<template>
	<v-container fluid>
		<div class="d-flex py-2">
			<v-btn color="primary" small class="ma-1" @click.stop="getClientes">
				<v-icon left>mdi-reload</v-icon>recargar
			</v-btn>

			<v-btn
				v-for="filtro in Object.keys(estadosFiltros)"
				:key="filtro"
				small
				text
				:class="{ 'ma-1': true, 'v-btn--active': estadosFiltros[filtro] }"
				:color="getSituacionIcon(filtro).color"
				@click.stop="estadosFiltros[filtro] = !estadosFiltros[filtro]"
				:disabled="clientes.filter(con => con.estado == filtro).length == 0"
			>
				<v-icon left>{{ getSituacionIcon(filtro).icon }}</v-icon>
				{{ clientes.filter(con => con.estado == filtro).length }} {{ filtro }}
			</v-btn>

			<v-spacer />
			<v-btn
				@click.stop="exportarTodos(selected)"
				text
				color="primary"
				small
				:disabled="selected.length == 0"
			>
				<v-icon left>mdi-download</v-icon>Descargar seleccionados
			</v-btn>
		</div>
		<v-card>
			<v-data-table
				fixed-header
				:items="morososFiltrados"
				:headers="clientesHeader"
				show-select
				class="fixed-checkbox"
				v-model="selected"
				item-key="IdCliente"
				:single-select="false"
				:calculate-widths="true"
				:search="search"
				checkbox-color="secondary"
				:loading="loading"
				:footer-props="{
					showFirstLastPage: true,
					showCurrentPage: true,
					itemsPerPageOptions: [10, 25, 50],
				}"
			>
				<template v-slot:body.prepend="{ headers }">
					<table-filters :headers="headers" :items="morososFiltrados" v-model="inlineFilters"></table-filters>
				</template>

				<template v-slot:item.estado="{ item }">
					<status-chip :value="(item.estado)" />
				</template>

				<template v-slot:item.Identidad="{ item }">
					<table-button
						:to="`/clientes/detalles?idCliente=${item.IdCliente}`"
						tooltip="Detalles del Cliente"
						color="secondary"
					>{{ item.Identidad }}</table-button>
				</template>

				<template v-slot:item.deudaActual="{ item }">{{ parseFloat(item.deudaActual || 0).toFixed(2) }} €</template>

				<template v-slot:item.creditLimitRequested="{ item }">{{ 	 Number(item.CESCE) ? parseFloat(item.creditLimitRequested).toFixed(2) + ' €' : null }}</template>
				<template v-slot:item.creditLimitGranted="{ item }">{{ 		 Number(item.CESCE) ? parseFloat(item.creditLimitGranted).toFixed(2) + ' €' : null }}</template>
				<template v-slot:item.commercialRiskCoverPct="{ item }">{{ Number(item.CESCE) ? item.commercialRiskCoverPct * 1 + ' %' : null }}</template>

				<template v-slot:item.nContratosActivados="{ item }">
					<v-chip label outlined class="v-chip--active" :color="getSituacionIcon('Activado').color">
						<v-icon left>{{ getSituacionIcon('Activado').icon }}</v-icon>
						{{ item.nContratosActivados }} Contratos
					</v-chip>
				</template>
				<template v-slot:item.nContratosBaja="{ item }">
					<v-chip label outlined class="v-chip--active" :color="getSituacionIcon('Baja').color">
						<v-icon left>{{ getSituacionIcon('Baja').icon }}</v-icon>
						{{ item.nContratosBaja }} Contratos
					</v-chip>
				</template>
				<template v-slot:item.impagadosActuales="{ item }">
					<v-chip label outlined class="v-chip--active" :color="getSituacionIcon('Impagado').color">
						<v-icon left>{{ getSituacionIcon('Impagado').icon }}</v-icon>
						{{ item.impagadosActuales }} Impagados
					</v-chip>
				</template>

				<template v-slot:item.acciones="{ item }">
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								@click.stop="detailsId = item.IdCliente"
								icon
								color="secondary"
								v-bind="attrs"
								v-on="on"
							>
								<v-icon>mdi-information-outline</v-icon>
							</v-btn>
						</template>
						Ver motivo
					</v-tooltip>
				</template>

				<template v-slot:item.CESCE="{ item }">
					<v-icon small v-if="item.CESCE == '0'" color="error">mdi-close-circle-outline</v-icon>
					<v-icon small v-else color="success">mdi-checkbox-marked-circle-outline</v-icon>
				</template>

				<template v-slot:item.consumosEstimados="{ item }">
					<!-- <table-button :to="`/clientes/detalles?idCliente=${item.IdCliente}`" tooltip="Detalles del Cliente" color="secondary">{{ item.Identidad }}</table-button> -->
					<span v-if="item.consumosEstimados">{{ redondear(item.consumosEstimados) }}</span>
				</template>
				<template v-slot:item.Email="{ item }">
					<v-tooltip bottom>
						<template v-slot:activator="{ on, attrs }">
							<v-btn
								v-on="on"
								v-bind="attrs"
								link
								:href="`mailto:${item.Email}`"
								color="secondary"
								text
								v-show="item.Email"
							>{{ item.Email }}</v-btn>
						</template>
						<span>Enviar Email</span>
					</v-tooltip>
				</template>
			</v-data-table>
		</v-card>

		<v-dialog
			max-width="800"
			:value="detailsId != null"
			@input="v => v === false ? detailsId = null : null"
		>
			<v-card :loading="Object.keys(motivoDesglosado).length == 0" class="py-4">
				<div class="d-flex align-center">
					<v-subheader>Motivo desglosado</v-subheader>
					<table-button
						v-if="Object.keys(motivoDesglosado).length > 0"
						class="d-inline"
						:to="`/clientes/detalles?idCliente=${motivoDesglosado[0].IdCliente}`"
						tooltip="Detalles del cliente"
						color="secondary"
						small
					>{{ motivoDesglosado[0].Identidad }}</table-button>
				</div>
				<v-divider />
				<div v-for="(con, index) in motivoDesglosado" :key="con.CodigoContrato" class="pa-3">
					<div class="d-flex align-center">
						<div class="d-flex">
							<v-icon left small>mdi-arrow-right-bold</v-icon>Baja del contrato
						</div>
						<table-button
							class="d-inline"
							:to="`/contratos/detalles?idContrato=${con.IdContrato}&codigoContrato=${con.CodigoContrato}`"
							tooltip="Detalles del contrato"
							color="secondary"
						>{{ con.CodigoContrato }}</table-button>el
						<span class="text-button mx-2">{{ parseDate(con.FechaBaja) }}</span> debiendo:
					</div>
					<v-list dense>
						<v-list-item-group>
							<v-list-item
								@click="verPDFfactura(imp.IdDocumento)"
								v-for="imp in con.impagados"
								:key="imp.IdCarteraCobro"
							>
								<v-list-item-content>
									<v-list-item-title>{{ imp.SerieFactura }} {{ imp.NumeroFactura }}</v-list-item-title>
									<v-list-item-subtitle>{{ imp.ImporteTotal }} €</v-list-item-subtitle>
								</v-list-item-content>
								<div class="d-flex flex-column text-end">
									<small class="caption">desde {{ parseDate(imp.FechaImpagado) }}</small>
									<small
										v-show="imp.FechaCobro"
										class="caption success--text"
									>hasta {{ parseDate(imp.FechaCobro) }}</small>
								</div>
								<v-list-item-action v-if="$root.acceso('CONTROL_IMPAGADOS')">
									<v-tooltip bottom>
										<template v-slot:activator="{ on, attrs }">
											<v-btn
												v-bind="attrs"
												v-on="on"
												small
												v-if="imp.CodigoSituacionCobro == 3"
												icon
												color="error"
												@click.stop="$router.push({ name: 'ControlImpagados', query: { filters: { Identificador: imp.Identidad, } } })"
											>
												<v-icon>mdi-currency-eur-off</v-icon>
											</v-btn>
										</template>
										Ver en impagados
									</v-tooltip>
								</v-list-item-action>
							</v-list-item>
						</v-list-item-group>
						<v-divider class="mx-auto" inset />
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title>
									{{ parseFloat(con.total).toFixed(2) }} €
									<span class="mx-2">-</span>
									{{ parseFloat(con.recuperado).toFixed(2) }} €
									<span class="mx-2">=</span>
									<v-chip small label>{{ parseFloat(con.total - con.recuperado).toFixed(2) }} €</v-chip>
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list>

					<v-divider v-if="index < motivoDesglosado.length - 1"></v-divider>
				</div>
			</v-card>
		</v-dialog>

		<div
			class="d-flex flex-wrap mt-4"
			style="gap: 16px"
			v-if="$root.acceso('DESCARGAR_CSV_CONTRATOS')"
		>
			<v-btn text color="primary" @click="exportarTodos">
				<v-icon left>mdi-download</v-icon>Exportar todos
			</v-btn>
		</div>

		<portal-target name="filter-btn" />
	</v-container>
</template>

<script>
// import axios from "redaxios";
import { perColumnFilter, jsonToCsv, parseDate, getSituacionIcon, downloadFile } from "@/utils/index.js";

export default {
	components: {
		TableFilters: () => import("@/components/TableFilters.vue"),
		TableButton: () => import("@/components/TableButton.vue"),
		StatusChip: () => import("@/components/StatusChip.vue"),
	},
	computed: {
		morososFiltrados() {
			return this.clientes.filter((con) => {
				for (let [key, value] of Object.entries(this.estadosFiltros))
					if (con.estado == key) return value;
				return true;
			})
		}
	},
	data() {
		return {
			selected: [],
			clientes: [],
			loading: false,
			search: null,
			detailsId: null,
			clientesHeader: [
				{ text: "Estado", value: "estado", dataType: 'select' },
				{ text: "Deuda actual", value: "deudaActual", align: 'end' },
				{ text: "Identificador", value: "Identidad" },
				{ text: "Contratos Activados", value: "nContratosActivados" },
				{ text: "Contratos Baja", value: "nContratosBaja" },
				{ text: "Impagados Actuales", value: "impagadosActuales" },
				{ text: "Acciones", value: "acciones", sortable: false, filterable: false },
				{ text: "CESCE", value: "CESCE", dataType: 'bool', align: 'center', acceso: 'CONTROL_MONITORIO_IMPAGADOS' },
				{ text: "Crédito Solicitados", value: "creditLimitRequested", align: 'end' },
				{ text: "Crédito Concedidos", value: "creditLimitGranted", align: 'end' },
				{ text: "Riesgo comercial cubierto", value: "commercialRiskCoverPct", align: 'end' },
				{ text: "Nombre Cliente", value: "denominacion" },
				{ text: "Dirección", value: "direccion" },
				{ text: "Móvil", value: "movil" },
				{ text: "Fijo", value: "fijo" },
				{ text: "Email", value: "email" },
			].filter(header => (!header.acceso || this.$root.acceso(header.acceso))).map((header) => {
				return {
					class: "text-no-wrap sticky-header",
					cellClass: "pa-2 text-no-wrap",
					filter: (value) =>
						perColumnFilter(
							value,
							this.inlineFilters[header.value],
							header.dataType
						),
					dataType: "text",
					...header,
				};
			}),

			estadosFiltros: {
				'Pago regularizado': false,
			},

			inlineFilters: {},

			motivoDesglosado: []
		};
	},
	methods: {
		getSituacionIcon,
		parseDate,
		redondear(bytes, dp = 3) {
			const thresh = 1000;

			if (Math.abs(bytes) < thresh) {
				return bytes + " B";
			}

			const units = ["kWh", "MWh", "GWh", "TWh", "PWh", "EWh", "ZWh", "YWh"];
			let u = -1;
			const r = 10 ** dp;

			do {
				bytes /= thresh;
				++u;
			} while (
				Math.round(Math.abs(bytes) * r) / r >= thresh &&
				u < units.length - 1
			);

			return bytes.toFixed(dp) + " " + units[u];
		},
		exportarTodos(items) {
			const getDatatype = (key) =>
				(this.clientesHeader.find((h) => h.value == key) || {}).dataType;

			if (!Array.isArray(items))
				items = this.clientes.filter((con) =>
					Object.entries(con).every(([key, value]) =>
						perColumnFilter(value, this.inlineFilters[key], getDatatype(key))
					)
				);

			const csvHeaders = Object.keys(items[0]).map(k => ({ key: k, dataType: getDatatype(k) }))

			jsonToCsv(items, csvHeaders, 'MOROSOS_' + parseDate(Date.now(), false, false));
		},
		getClientes() {
			this.loading = true;
			let str = this.$store.getters.getDatosEmpresa;

			const { IdUsuario, IdAgente } = str.iss;
			const IdEmpresa = this.$store.getters.getIdEmpresa;

			axios({
				method: "GET",
				url: `${process.env.VUE_APP_OUR_API_URL}/morosos_clientes.php`,
				params: {
					token: this.$store.getters.getJwtEmpresa,
					IdAgente,
				}
			}).then(({ data }) => {
				this.clientes = [
					...new Map(data.map((item) => [item.Identidad, item])).values(),
				].map((item) => {
					item.estado = Number(item.deudaActual) > 0 ? 'Pagos pendientes' : 'Pago regularizado'
					return {
						...item,
						denominacion: [item.Apellido1, item.Apellido2, item.Nombre, item.RazonSocial]
							.filter((i) => !!i)
							.join(" "),
						direccion: [item.NombreCalle, item.NumeroTitular, ',', item.TextoCiudad, item.CodPostal, item.TextoProvincia]
							.filter((i) => !!i)
							.join(" "),

					};
				});
				this.loading = false;
			});
		},
		verPDFfactura(idDocumento) {
			let str = this.$store.getters.getDatosEmpresa;
			const { IdUsuario } = str.iss;
			const IdEmpresa = this.$store.getters.getIdEmpresa;
			axios({
				method: "get",
				url: `${process.env.VUE_APP_API_URL}/FacturaVentaCabecera/FacturaPreviewPDF/Empresa/${IdEmpresa}/Usuario/${IdUsuario}/Documento/${idDocumento}`,
				responseType: "blob",
			})
				.then((res) => {
					let pdf = new File(
						[res.data],
						`factura_${new Date().getTime()}.pdf`,
						{
							type: "application/pdf",
						}
					);
					downloadFile(pdf, true);
				})
				.catch((err) => {
					this.$root.$emit("snack", "Ha ocurrido un error");
				});
		},
	},
	mounted() {
		this.getClientes();
	},
	watch: {
		detailsId(v) {
			if (v == null) {
				this.motivoDesglosado = [];
				return;
			};
			axios({
				url: `${process.env.VUE_APP_OUR_API_URL}/morosos_clientes.php`, method: 'GET', params: {
					IdCliente: v,
					token: this.$store.getters.getJwtEmpresa
				}
			}).then(({ data }) => {

				const contratos = [...new Set(data.contratosBaja.map(c => c.CodigoContrato))].map(c => {
					const impagados = data.contratosBaja.filter(imp => imp.CodigoContrato == c)
					return {
						...data.contratosBaja.find(con => con.CodigoContrato === c),
						impagados,
						total: impagados.reduce((a, c) => Number(c.ImporteTotal) + a, 0),
						recuperado: impagados.filter(i => (i.FechaCobro || i.FechaCargo || i.FechaComienzoRemesa) && i.CodigoSituacionCobro != 3).reduce((a, c) => Number(c.ImporteTotal) + a, 0)
					}
				})

				this.motivoDesglosado = contratos;
			})
		}
	}
};
</script>

<style scoped>
.v-data-table /deep/ [role="columnheader"] {
	/* background: #f1f1f1 !important; */
}
</style>